import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

import apple from "../assets/icons/apple.svg";
import play from "../assets/icons/play.svg";
import Facebook from "../assets/icons/facebook.svg";
import Instagram from "../assets/icons/instagram.svg";
import Youtube from "../assets/icons/youtube.svg";

const Footer: React.FC = () => {
  return (
    <>
      <Flex
        position="absolute"
        bottom="10px"
        left="0"
        w="100%"
        padding="0 20px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex flexDirection="column" gap="5px" alignItems="center">
          <Flex
            h="15px"
            alignItems="center"
            position="absolute"
            justifyContent="center"
            gap="10px"
            zIndex="3"
            bottom="35px"
          >
            <Box
              cursor="pointer"
              position="relative"
              w="15px"
              h="15px"
            >
              <a href="https://www.facebook.com/profile.php?id=61552778795698&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                <Image
                  src={Facebook}
                />
              </a>
            </Box>
            <Box
              cursor="pointer"
              position="relative"
              w="15px"
              h="15px"
            >
              <a href="https://instagram.com/mixfmsyria?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
                <Image
                  src={Instagram}
                />
              </a>
            </Box>
            <Box
              cursor="pointer"
              position="relative"
              w="15px"
              h="15px"
            >
              <a href="https://youtube.com/channel/UCfx0CbvWTRx_zp1EXMaCTPQ" target="_blank" rel="noopener noreferrer">
                <Image
                  src={Youtube}
                />
              </a>
            </Box>
          </Flex>
          <Flex
            zIndex="3"
            fontSize="10px"
            w="100%"
            alignItems="center"
            color="#F2F2F2"
            flexDirection="column"
          >
            <Text>MIX FM , Copyright © 2014 - 2021</Text>
            <a href="https://orkabit.com/">
              Powered by <span style={{ fontWeight: "bold" }}>ORKA</span>BIT
            </a>
          </Flex>
        </Flex>
        <Flex gap="10px" justifyContent="space-around">
          <Box cursor="pointer" position="relative" w="72px" h="20px">
            <Image
              w="100%"
              minW="100%"
              h="100%"
              minH="100%"
              position="absolute"
              src={apple}
            />
          </Box>
          <Box cursor="pointer" position="relative" w="72px" h="20px">
            <Image
              w="100%"
              minW="100%"
              h="100%"
              minH="100%"
              position="absolute"
              src={play}
            />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Footer;
