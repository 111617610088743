import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import Slider from "./components/Slider";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import { IChildren } from "./components/Layout";
import axios from "axios";

export interface IData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}
function App() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location: any = useLocation();
  const [data, setData] = React.useState(null);

  const getData = async () => {
    await axios
      .get(
        "https://client.orkabit.com/api/v1/page?route=/&domain=mixfmsyria.net"
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then(function (response: any) {
        setData(response.data.page_data);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch(function (error: any) {
        console.log(error);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .finally(function () {});
  };
  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Layout data={data}>
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <AnimateDev>
                <Slider data={data} />
              </AnimateDev>
            }
          />
          <Route
            path="/contact-us"
            element={
              <AnimateDev>
                <ContactUs data={data} />
              </AnimateDev>
            }
          />
          <Route
            path="about-us"
            element={
              <AnimateDev>
                <AboutUs />
              </AnimateDev>
            }
          />
        </Routes>
      </AnimatePresence>
      {/* </AnimateDev> */}
    </Layout>
  );
}

export default App;

export const AnimateDev: React.FC<IChildren> = ({ children }) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        top: "44%",
        left: "50%",
        width: "100%",
        transform: "translate(-50% , -50%)",
      }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};
