import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";

import mixFm from "../assets/icons/mixFm.svg";
import apple from "../assets/icons/apple.svg";
import play from "../assets/icons/play.svg";

import Facebook from "../assets/icons/facebook.svg";
import Instagram from "../assets/icons/instagram.svg";
import Youtube from "../assets/icons/youtube.svg";

import { Link } from "react-router-dom";

interface IProps {
  setNavabr: React.Dispatch<React.SetStateAction<number>>;
  navbar: number;
}

const Navbar: React.FC<IProps> = ({ setNavabr, navbar }) => {
  return (
    <Flex
      paddingTop={{ base: "20px", md: "30px", lg: "40px" }}
      maxH="127px"
      alignItems="center"
      justifyContent={{ base: "center", md: "space-between" }}
      gap="15px"
      color="#fff"
      w="100%"
      position="relative"
    >
      <Flex
        display={{ base: "none", md: "flex" }}
        alignItems="center"
        justifyContent="center"
        gap="10px"
      >
        <Box
          cursor="pointer"
          position="relative"
          w={{ md: "20px", lg: "35px" }}
          h={{ md: "20px", lg: "40px" }}
        >
          <a href="https://www.facebook.com/profile.php?id=61552778795698&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
            <Image
              src={Facebook}
            />
          </a>
        </Box>
        <Box
          cursor="pointer"
          position="relative"
          w={{ md: "20px", lg: "35px" }}
          h={{ md: "20px", lg: "40px" }}
        >
          <a href="https://www.instagram.com/mixfmsyria" target="_blank" rel="noopener noreferrer">
            <Image
              src={Instagram}
            />
          </a>
        </Box>
        <Box
          cursor="pointer"
          position="relative"
          w={{ md: "20px", lg: "35px" }}
          h={{ md: "20px", lg: "40px" }}
        >
          <a href="https://youtube.com/channel/UCfx0CbvWTRx_zp1EXMaCTPQ" target="_blank" rel="noopener noreferrer">
            <Image
              src={Youtube}
            />
          </a>
        </Box>
      </Flex>
      <Flex
        fontSize={{ base: "12px", md: "14px", lg: "18px", xl: "20px" }}
        w="100%"
        maxW={{ base: "210px", md: "270px", lg: "400px", xl: "450px" }}
        alignItems="center"
        justifyContent="space-between"
        color="#FFFFFF"
      >
        <Box
          cursor="pointer"
          onClick={() => {
            setNavabr(2);
          }}
          opacity={navbar !== 2 ? "0.5" : "1"}
          fontWeight="bold"
        >
          <Link to="/about-us">About</Link>
        </Box>
        <Box
          opacity={navbar !== 0 ? "0.5" : "1"}
          cursor="pointer"
          onClick={() => {
            setNavabr(0);
          }}
          position="relative"
          w={{ base: "70px", md: "92px", lg: "109px", xl: "129px" }}
          h={{ base: "45px", md: "46px", lg: "67px", xl: "77px" }}
        >
          <Link to="/">
            <Image
              w="100%"
              minW="100%"
              h="100%"
              minH="100%"
              position="absolute"
              src={mixFm}
            />
          </Link>
        </Box>
        <Box
          cursor="pointer"
          onClick={() => {
            setNavabr(1);
          }}
          opacity={navbar !== 1 ? "0.5" : "1"}
          fontWeight="bold"
        >
          <Link to="/contact-us"> Contact us</Link>
        </Box>
      </Flex>
      <Flex
        display={{ base: "none", md: "flex" }}
        alignItems="center"
        justifyContent="center"
        gap="30px"
      >
        <Box
          cursor="pointer"
          position="relative"
          w={{ md: "70px", lg: "96px", xl: "116px" }}
          h={{ md: "20px", lg: "30px" }}
        >
          <Image
            w="100%"
            minW="100%"
            h="100%"
            minH="100%"
            position="absolute"
            src={apple}
          />
        </Box>
        <Box
          cursor="pointer"
          position="relative"
          w={{ md: "70px", lg: "96px", xl: "116px" }}
          h={{ md: "20px", lg: "30px" }}
        >
          <Image
            w="100%"
            minW="100%"
            h="100%"
            minH="100%"
            position="absolute"
            src={play}
          />
        </Box>
      </Flex>

      <Box
        position="absolute"
        transform="translateX(-50%)"
        bottom="-10px"
        left={{ base: "50%", md: "50%" }}
        w={{ base: "250px", md: "100%" }}
        h="2px"
        bg="#11128C"
        // bg="white"
        opacity="0.2"
      />
    </Flex>
  );
};

export default Navbar;
