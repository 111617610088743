/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, Image } from "@chakra-ui/react";

import React, { useEffect, useRef, useState } from "react";
import { IData } from "../App";
import Slider1 from "../assets/slider/slider1.jpg";
import Slider2 from "../assets/slider/slider2.jpg";
import Slider3 from "../assets/slider/slider3.jpg";

export interface ISlider {
  windowSize: {
    width: number;
    height: number;
  };
}
interface ISwipe {
  setBeforeSwipe: React.Dispatch<React.SetStateAction<number>>;
  setAfterSwipe: React.Dispatch<React.SetStateAction<number>>;

  sliderDrag: (e: React.DragEvent<HTMLLabelElement>) => void;
  sliderTouch: (e: React.TouchEvent<HTMLLabelElement>) => void;
  setBeforeDrag: React.Dispatch<React.SetStateAction<number>>;
  setAfterDrag: React.Dispatch<React.SetStateAction<number>>;
  sliderId: string;
  numberId: string;
  src: string;
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  slider: () => void;
  children?: JSX.Element;
}

const Slider: React.FC<IData> = ({ data }) => {
  const [windowSize, setWindowSize] = useState<ISlider["windowSize"]>({
    width: 0,
    height: 0,
  });

  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);

      handleResize();
      // isMobile;

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  const numberIndex = React.useRef<number>(1);
  const [beforeDrag, setBeforeDrag] = React.useState<number>(0);
  const [afterDrag, setAfterDrag] = React.useState<number>(0);
  const [beforeSwipe, setBeforeSwipe] = React.useState<number>(0);
  const [afterSwipe, setAfterSwipe] = React.useState<number>(0);
  const bool = useRef<boolean>(false);
  const arr = [Slider1, Slider2, Slider3, Slider1, Slider2, Slider3];
  const isMobile: boolean =
    windowSize.width !== 0 && windowSize.width < 768 ? true : false;

  const transformXSliderFirstRight: string = !isMobile ? "35%" : "45%";
  const transformXSliderSecondRight: string = isMobile ? "120%" : "70%";
  const transformXSliderFirstLeft: string = !isMobile ? "-35%" : "-45%";
  const transformXSliderSecondLeft: string = isMobile ? "-120%" : "-70%";
  const transformZSliderFirst: string = isMobile ? "-750px" : "-350px";
  const transformZSliderSecond = "-1000px";
  const opacityFirst = 1;
  const opacitySecond = 0;
  let y: NodeListOf<HTMLInputElement>;
  let x: NodeListOf<HTMLLabelElement>;
  let divChildren: NodeListOf<HTMLDivElement>;
  const [sliderAtCall, setSliderAtCall] = React.useState<boolean>(false);

  const slider = (): void => {
    setSliderAtCall(true);
    // console.log(isMobile);
    sliderAuto();

    // console.log(sliderAtCall, "slider fun");
    bool.current = true;
    // if (counter > 1) return;

    const x1 = document.getElementById("slider") as HTMLElement | null;
    if (x1 !== null) {
      x = x1.querySelectorAll("label") as NodeListOf<HTMLLabelElement>;
      divChildren = x1.querySelectorAll("div") as NodeListOf<HTMLDivElement>;
    }
    const y1 = document.getElementById("slider") as HTMLElement | null;
    if (y1 !== null) {
      y = y1.querySelectorAll("input") as NodeListOf<HTMLInputElement>;
    }
    const arr = [];
    if (x !== undefined) {
      for (let i = 0; i < x.length; i++) {
        arr.push(i);
      }
    }

    const newArr = [];
    if (x !== undefined) {
      for (let i = 0; i < x.length; i++) {
        if (x.length <= 3) {
          if (y[0].checked === true) {
            x[0].setAttribute(
              "style",
              `opacity:1 ; z-index:3;  transform: translate3d(0px, 0, 0px); `
            );
            x[1].setAttribute(
              "style",
              `opacity:${opacityFirst};z-index:2;transform: translate3d(${transformXSliderFirstRight}, 0, ${transformZSliderFirst});`
            );
            x[2].setAttribute(
              "style",
              `opacity:${opacityFirst} ;z-index:2;transform: translate3d(${transformXSliderFirstLeft}, 0, ${transformZSliderFirst});`
            );
            divChildren[0].setAttribute(
              "style",
              `opacity:${opacityFirst};pointer-events:all`
            );
            divChildren[1].setAttribute(
              "style",
              `opacity:${opacityFirst};pointer-events:all`
            );
            divChildren[2].setAttribute(
              "style",
              `opacity:${opacityFirst};pointer-events:all`
            );
          }
          if (y[1].checked === true) {
            x[1].setAttribute(
              "style",
              "opacity:1 ; z-index:3; transform: translate3d(0px, 0, 0px);"
            );
            x[2].setAttribute(
              "style",
              `opacity:${opacityFirst};z-index:2;transform: translate3d(${transformXSliderFirstRight}, 0, ${transformZSliderFirst});`
            );
            x[0].setAttribute(
              "style",
              `opacity:${opacityFirst};z-index:2;transform: translate3d(${transformXSliderFirstLeft}, 0, ${transformZSliderFirst});`
            );
            divChildren[1].setAttribute(
              "style",
              `opacity:${opacityFirst};pointer-events:all`
            );
            divChildren[2].setAttribute(
              "style",
              `opacity:${opacityFirst};pointer-events:all`
            );
            divChildren[0].setAttribute(
              "style",
              `opacity:${opacityFirst};pointer-events:all`
            );
          }
          if (y[2].checked === true) {
            x[2].setAttribute(
              "style",
              "opacity:1 ; z-index:3; transform: translate3d(0px, 0, 0px);"
            );
            x[0].setAttribute(
              "style",
              `opacity:${opacityFirst};z-index:2;transform: translate3d(${transformXSliderFirstRight}, 0, ${transformZSliderFirst});`
            );
            x[1].setAttribute(
              "style",
              `opacity:${opacityFirst};z-index:2;transform: translate3d(${transformXSliderFirstLeft}, 0, ${transformZSliderFirst});`
            );
            divChildren[2].setAttribute(
              "style",
              `opacity:${opacityFirst};pointer-events:all`
            );
            divChildren[0].setAttribute(
              "style",
              `opacity:${opacityFirst};pointer-events:all`
            );
            divChildren[1].setAttribute(
              "style",
              `opacity:${opacityFirst};pointer-events:all`
            );
          }
        } else if (y[i].checked) {
          numberIndex.current = i;
          for (let k = i; k < x.length; k++) {
            newArr.push(k);
          }
          for (let index = 0; index < i; index++) {
            newArr.push(index);
          }

          for (let j = 0; j < newArr.length; j++) {
            if (j === 0) {
              x[newArr[j]].setAttribute(
                "style",
                "opacity:1 ; z-index:3; transform: translate3d(0px, 0, 0px);"
              );
              divChildren[newArr[j]].setAttribute(
                "style",
                `opacity:1; pointer-events:all `
              );
            } else if (j === 1) {
              x[newArr[j]].setAttribute(
                "style",
                `opacity:${opacityFirst};z-index:2;transform: translate3d(${transformXSliderFirstRight}, 0, ${transformZSliderFirst});`
              );
              divChildren[newArr[j]].setAttribute(
                "style",
                `opacity:${opacityFirst}; pointer-events:all `
              );
            } else if (j === 2) {
              x[newArr[j]].setAttribute(
                "style",
                `opacity:${opacitySecond};z-index:2;transform: translate3d(${transformXSliderSecondRight}, 0, ${transformZSliderSecond});`
              );
              divChildren[newArr[j]].setAttribute(
                "style",
                `opacity:${opacitySecond};pointer-events:none`
              );
            } else if (j === newArr.length - 1 && newArr.length - 1 > 2) {
              x[newArr[j]].setAttribute(
                "style",
                `opacity:${opacityFirst};z-index:2;;transform: translate3d(${transformXSliderFirstLeft}, 0, ${transformZSliderFirst});`
              );
              divChildren[newArr[j]].setAttribute(
                "style",
                `opacity: 1; pointer-events:all `
              );
            } else if (j === newArr.length - 2 && newArr.length - 1 > 3) {
              x[newArr[j]].setAttribute(
                "style",
                `opacity:${opacitySecond};z-index:1;transform: translate3d(${transformXSliderSecondLeft}, 0, ${transformZSliderSecond});`
              );
              divChildren[newArr[j]].setAttribute(
                "style",
                `opacity:${opacitySecond}; pointer-events:none `
              );
            } else {
              x[newArr[j]].setAttribute(
                "style",
                "opacity:0 ; z-index:0;  transform: translate3d(-120%, 0, -750px);"
              );
              divChildren[newArr[j]].setAttribute(
                "style",
                `opacity: 0; pointer-events:none `
              );
            }
          }
        }
      }
    }
  };
  let xPlus: NodeListOf<HTMLInputElement>;
  let inputDrag: HTMLInputElement;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let inputDragBefore: HTMLInputElement;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let inputDragAafter: HTMLInputElement;
  let inputDragIndex: any;
  let inputDragBeforeIndex: number;
  let inputDragAafterIndex: number;

  const [counter, setCounter] = useState<number>(0);
  useEffect(() => {
    counter;
  }, [counter, setCounter]);

  const sliderDrag = (e: React.DragEvent<HTMLLabelElement>) => {
    const x1 = document.getElementById("slider") as HTMLElement | null;
    const idArr = [];
    if (x1 !== null) {
      xPlus = x1.querySelectorAll("input") as NodeListOf<HTMLInputElement>;
    }

    for (let i = 0; i < xPlus.length; i++) {
      idArr.push(xPlus[i].id);
      inputDragIndex = idArr[i].split("");
    }

    inputDragBeforeIndex = parseInt(inputDragIndex[1]) - 1;
    inputDragAafterIndex = parseInt(inputDragIndex[1]) + 1;
    if (inputDragBeforeIndex < 0) {
      inputDragBeforeIndex = idArr.length;
    }

    if (inputDragAafterIndex > idArr.length - 1) {
      inputDragAafterIndex = 1;
    }

    inputDrag = document.getElementById(
      `${e.currentTarget.htmlFor}`
    ) as HTMLInputElement;
    inputDragBefore = document.getElementById(
      `s${inputDragBeforeIndex}`
    ) as HTMLInputElement;
    inputDragAafter = document.getElementById(
      `s${inputDragAafterIndex}`
    ) as HTMLInputElement;

    if (inputDrag?.checked) {
      if (beforeDrag - afterDrag > windowSize.width / 13) {
        xPlus[
          numberIndex.current + 1 > xPlus.length - 1
            ? 0
            : numberIndex.current + 1
        ].checked = true;
        xPlus[
          numberIndex.current - 1 < 0
            ? xPlus.length - 1
            : numberIndex.current - 1
        ].checked = false;

        if (counter <= 1) slider();
      } else if (beforeDrag - afterDrag < -windowSize.width / 16) {
        xPlus[
          numberIndex.current + 1 > xPlus.length - 1
            ? 0
            : numberIndex.current + 1
        ].checked = false;
        xPlus[
          numberIndex.current - 1 < 0
            ? xPlus.length - 1
            : numberIndex.current - 1
        ].checked = true;

        if (counter <= 1) slider();
      }
    }
  };
  const sliderTouch = (e: React.TouchEvent<HTMLLabelElement>) => {
    const idArr = [];
    const x1 = document.getElementById("slider") as HTMLElement | null;
    if (x1 !== null) {
      xPlus = x1.querySelectorAll("input") as NodeListOf<HTMLInputElement>;
    }
    for (let i = 0; i < xPlus.length; i++) {
      idArr.push(xPlus[i].id);
      inputDragIndex = idArr[i].split("");
    }

    inputDragBeforeIndex = parseInt(inputDragIndex[1]) - 1;
    inputDragAafterIndex = parseInt(inputDragIndex[1]) + 1;
    if (inputDragBeforeIndex < 0) {
      inputDragBeforeIndex = idArr.length;
    }

    if (inputDragAafterIndex > idArr.length - 1) {
      inputDragAafterIndex = 1;
    }

    inputDrag = document.getElementById(
      `${e.currentTarget.htmlFor}`
    ) as HTMLInputElement;
    inputDragBefore = document.getElementById(
      `s${inputDragBeforeIndex}`
    ) as HTMLInputElement;
    inputDragAafter = document.getElementById(
      `s${inputDragAafterIndex}`
    ) as HTMLInputElement;
    if (inputDrag?.checked) {
      if (beforeSwipe - afterSwipe > windowSize.width / 13) {
        xPlus[
          numberIndex.current + 1 > xPlus.length - 1
            ? 0
            : numberIndex.current + 1
        ].checked = true;
        xPlus[
          numberIndex.current - 1 < 0
            ? xPlus.length - 1
            : numberIndex.current - 1
        ].checked = false;

        if (counter <= 1) slider();
      } else if (beforeSwipe - afterSwipe < -windowSize.width / 16) {
        xPlus[
          numberIndex.current + 1 > xPlus.length - 1
            ? 0
            : numberIndex.current + 1
        ].checked = false;
        xPlus[
          numberIndex.current - 1 < 0
            ? xPlus.length - 1
            : numberIndex.current - 1
        ].checked = true;

        if (counter <= 1) slider();
      }
    }
  };

  const sliderAuto = () => {
    const x1 = document.getElementById("slider") as HTMLElement | null;
    if (x1 !== null) {
      xPlus = x1.querySelectorAll("input") as NodeListOf<HTMLInputElement>;
    }

    if (bool.current) return;

    setInterval(() => {
      if (!sliderAtCall) {
        xPlus[
          numberIndex.current + 1 > xPlus.length - 1
            ? 0
            : numberIndex.current + 1
        ].checked = true;
        xPlus[
          numberIndex.current - 1 < 0
            ? xPlus.length - 1
            : numberIndex.current - 1
        ].checked = false;

        slider();
      }
    }, 5000);
  };

  const renderImage = (): JSX.Element[] => {
    return arr.map((img, index) => {
      return (
        <SliderItem
          sliderTouch={sliderTouch}
          counter={counter}
          setCounter={setCounter}
          key={img + index}
          setBeforeSwipe={setBeforeSwipe}
          setAfterSwipe={setAfterSwipe}
          sliderDrag={sliderDrag}
          setBeforeDrag={setBeforeDrag}
          setAfterDrag={setAfterDrag}
          slider={slider}
          src={img}
          sliderId={`slider${index + 1}`}
          numberId={`s${index + 1}`}
        />
      );
    });
  };
  const renderImageFetched = (): JSX.Element[] => {
    return data?.slider._image.map((img: string, index: number) => {
      return (
        <SliderItem
          sliderTouch={sliderTouch}
          counter={counter}
          setCounter={setCounter}
          key={img + index}
          setBeforeSwipe={setBeforeSwipe}
          setAfterSwipe={setAfterSwipe}
          sliderDrag={sliderDrag}
          setBeforeDrag={setBeforeDrag}
          setAfterDrag={setAfterDrag}
          slider={slider}
          src={img}
          sliderId={`slider${index + 1}`}
          numberId={`s${index + 1}`}
        />
      );
    });
  };
  React.useEffect(() => {
    if (!data?.slider._image) return;
    const firstInputOnLoad = document.querySelectorAll(
      "input"
    ) as NodeListOf<HTMLInputElement>;
    firstInputOnLoad[0].checked = true;
    // sliderAuto();
    // slider();
    // sliderAtCall;
  }, [isMobile, windowSize.width, sliderAtCall, data?.slider._image]);

  useEffect(() => {
    slider();
  });
  return (
    <Flex
      paddingTop={{ base: "20px", md: "30px", lg: "40px" }}
      alignItems="center"
      justifyContent={{ base: "center", md: "center" }}
      gap="15px"
      color="#fff"
      w="100%"
      h={{ base: "250px", md: "400px", xl: "450px" }}
      position="relative"
      paddingBottom="35px"
      flexDirection={{ base: "column-reverse", md: "row" }}
    >
      <section
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          overflowX: "hidden",
          perspective: "1200px",
          transformStyle: "preserve-3d",
        }}
        id="slider"
      >
        {data?.slider._image.length < 2 ? renderImage() : renderImageFetched()}
      </section>
    </Flex>
  );
};

export default Slider;
export const SliderItem: React.FC<ISwipe> = ({
  setBeforeSwipe,
  setAfterSwipe,
  sliderDrag,
  numberId,
  slider,
  sliderTouch,
  src,
  sliderId,
  counter,
  setCounter,

  setAfterDrag,
  // item,
  setBeforeDrag,
  children,
}) => {
  const ref = React.useRef<HTMLInputElement>(null);

  const [cursor, setcursor] = useState<boolean>(false);
  useEffect(() => {
    counter;
  }, [counter, setCounter]);

  return (
    <label
      draggable={true}
      // initial={{ pointerEvents: "none" }}

      onDragEnter={(e) => {
        if (counter > 1) return;
        setcursor(true);
        setBeforeDrag(e.clientX);

        setCounter(counter + 1);
      }}
      onDragOver={(e) => {
        if (counter > 1) return;
        setcursor(false);
        setAfterDrag(e.clientX);

        if (counter === 1) sliderDrag(e);
      }}
      onDragEnd={() => setCounter(0)}
      onTouchStart={(e) => {
        if (counter > 1) return;
        setBeforeSwipe(e.touches[0].clientX);

        setCounter(counter + 1);
      }}
      onTouchMove={(e) => {
        if (counter > 1) return;
        setAfterSwipe(e.touches[0].clientX);

        setCounter(counter + 1);
        if (counter === 1) sliderTouch(e);
      }}
      onTouchEnd={() => setCounter(0)}
      htmlFor={numberId}
      id={sliderId}
    >
      <Flex
        cursor={cursor ? "grabbing" : "grab"}
        pointerEvents="all"
        w={{ base: "245px", md: "300px", lg: "450px", xl: "600px" }}
        h={{ base: "150px", md: "180px", lg: "230px", xl: "370px" }}
        position="relative"
        flexShrink="0"
        alignItems="flex-start"
        fontSize={{ base: "12px", md: "13px", lg: "20px" }}
        flexDirection="column"
      >
        <input
          style={{ display: " none" }}
          type="radio"
          name="slider"
          id={numberId}
          ref={ref}
          onClick={slider}
        />

        <Image borderRadius="20px" w="100%" h="100%" src={src} />
        {children}
      </Flex>
    </label>
  );
};
