import { Flex, Image, Input, Text, Textarea } from "@chakra-ui/react";
import React from "react";
import Phone from "../assets/icons/phone.svg";
import Telephone from "../assets/icons/telephone.svg";
import loaction from "../assets/icons/loaction.svg";
import { IData } from "../App";
import axios from "axios";

interface ICom {
  width: string;
  maxW?: string;
  height: string;
  src: string;
  text?: string;
  fontSize?: { base: string; md: string };
}

interface IForm {
  setMessage: React.Dispatch<
    React.SetStateAction<{
      email: string;
      name: string;
      message: string;
    }>
  >;
  message: {
    email: string;
    name: string;
    message: string;
  };
}

const ContactUs: React.FC<IData> = ({ data }) => {
  const [message, setMessage] = React.useState<IForm["message"]>({
    email: "",
    name: "",
    message: "",
  });
  const [sucsses, setSucsses] = React.useState<boolean>(false);
  const [err, setErr] = React.useState<string>("");

  const submit = (e: any) => {
    const newMessges: any = { ...message };
    newMessges[e.target.id] = e.target.value;
    setMessage(newMessges);
  };

  const submitMessage = async (e: any) => {
    e.preventDefault();
    if (
      message.email.length <= 1 &&
      message.message.length <= 1 &&
      message.name.length <= 1
    ) {
      setErr("please fill the required inputs");
      setTimeout(() => {
        setErr("");
      }, 4000);

      return;
    }
    await axios
      .post(
        "https://client.orkabit.com/api/v1/contact-us?domainName=mixfmsyria.net",
        {
          email: message.email,
          name: message.name,
          message: message.message,
        }
      )
      .then(function (response: any) {
        console.log(response);
      })
      .catch(function (error: any) {
        console.log(error);
      })
      .finally(function () {
        wordPlay();
        setTimeout(() => {
          setMessage({ email: "", name: "", message: "" });
        }, 3000);
      });
  };

  const wordPlay = () => {
    setSucsses(true);
    setTimeout(() => {
      setSucsses(false);
    }, 5000);
  };

  return (
    <Flex
      fontSize={{ base: "12px", md: "14px", lg: "16px", xl: "18px" }}
      maxW="590px"
      gap={{ base: "40px", md: "50px" }}
      color="#FCAC00"
      flexDirection="column"
      w={{ base: "90%", md: "100%" }}
      padding={{ base: "10px", md: "20px 15px ", lg: "40px 20px " }}
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        padding={{ base: "0 10px", md: "0 40px", lg: "0 60px" }}
        w="100%"
        gap="20px"
        justifyContent="space-between"
      >
        <Component
          text={data?.contactUs.phoneNumber}
          height="33px"
          width="23px"
          src={Phone}
        />
        <Component
          text={data?.contactUs.lineNumber}
          height="29px"
          width="29px"
          src={Telephone}
        />
        <Component
          text={data?.contactUs.address}
          height="33px"
          width="21px"
          maxW="90px"
          src={loaction}
          fontSize={{ base: "8px", md: "11px" }}
        />
      </Flex>
      <Flex w="100%" gap="20px" justifyContent="space-between" flexWrap="wrap">
        <Input
          background=" rgba(255, 255, 255, 0.2)"
          id="name"
          value={message.name}
          onChange={(e) => submit(e)}
          backdropFilter="blur(8px)"
          border="0"
          w={{ base: "46%", md: "47%" }}
          color="#FCAC00"
          type="text"
          placeholder="Name"
        />
        <Input
          background=" rgba(255, 255, 255, 0.2)"
          backdropFilter="blur(8px)"
          id="email"
          onChange={(e) => submit(e)}
          value={message.email}
          border="0"
          w={{ base: "46%", md: "47%" }}
          color="#FCAC00"
          type="email"
          placeholder="E-mail"
        />
        <Textarea
          id="message"
          required
          value={message.message}
          onChange={(e) => submit(e)}
          background=" rgba(255, 255, 255, 0.2)"
          backdropFilter="blur(8px)"
          border="0"
          h="150px"
          placeholder="Message"
          color="#FCAC00"
          w="100%"
        />
      </Flex>
      <Flex
        cursor="pointer"
        borderRadius="4px"
        justifyContent="center"
        alignItems="center"
        w={{ base: "120px", md: "160px" }}
        h={{ base: "30px", md: "43px" }}
        textAlign="center"
        onClick={submitMessage}
        bg={err.length === 0 ? "#FCAC00" : "red.600"}
        color="#fff"
      >
        {sucsses && err.length === 0 ? (
          "  Thank you for your message "
        ) : err.length !== 0 ? (
          err
        ) : (
          <>Send</>
        )}
      </Flex>
    </Flex>
  );
};

export default ContactUs;

export const Component: React.FC<ICom> = ({
  width,
  height,
  src,
  text,
  ...props
}) => {
  return (
    <Flex
      maxW="150px"
      gap="10px"
      flexDirection="column"
      alignItems="center"
      cursor="pointer"
      position="relative"
      {...props}
    >
      <Image src={src} w={width} h={height} />
      <Text textAlign="center">{text}</Text>
    </Flex>
  );
};
