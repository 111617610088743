import { Flex, Text } from "@chakra-ui/react";
import React from "react";

const AboutUs = () => {
  return (
    <Flex
      fontSize={{ base: "13px", md: "15px", lg: "18px", xl: "20px" }}
      maxW="780px"
      gap={{ base: "10px", md: "20px" }}
      color="#fff"
      flexDirection="column"
      w={{ base: "90%", md: "100%" }}
      padding={{ base: "10px", md: "20px 15px ", lg: "40px 20px " }}
      justifyContent="center"
      alignItems="start"
    >
      <Text
        padding="10px 0 "
        fontSize={{ base: "13px", md: "15px", lg: "20px", xl: "24px" }}
        fontWeight="bold"
      >
        Mix FM Syria Radio Station is the 1st English Radio on 105.7 – 24/7
      </Text>
      <Text className="dot-text" fontWeight="semibold">
        Mix FM Syria Mix FM Syria began on March 2007.
      </Text>

      <Text className="dot-text" fontWeight="semibold">
        Emits to Damascus and Aleppo.
      </Text>
      <Text className="dot-text" fontWeight="semibold">
        Mix FM Syria Radio Station has various music styles - R&B, Hip-Hop, Rap
        and Pop Songs, Light and Slow Beats, and many, many more.
      </Text>
      <Text className="dot-text" fontWeight="semibold">
        Targeted for the middle aged and young generation
      </Text>
      <Text className="dot-text" fontWeight="semibold">
        Slogan: " Proud 2B Syrian "
      </Text>
    </Flex>
  );
};

export default AboutUs;
