import { Box, Flex, Image } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import Play from "../assets/icons/player.svg";
import Pause from "../assets/icons/pause.svg";
import Down from "../assets/icons/volumeDown.svg";
import Up from "../assets/icons/volumeUp.svg";
import { IData } from "../App";

const Player: React.FC<IData> = ({ data }) => {
  const [musicChange, setMusicChange] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const audioRef = useRef<any>(null);

  const PlayPauseMusic = () => {
    if (audioRef.current === null) return;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const playMusic = audioRef.current.audioEl.current.play();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const pauseMusic = audioRef.current.audioEl.current.pause();

    // if (musicChange) pauseMusic;
    // else playMusic;
    setMusicChange(!musicChange);
  };

  const changeVolume = (isDown: boolean) => {
    if (!isDown) {
      if (audioRef.current.audioEl.current.volume === 1)
        audioRef.current.audioEl.current.volume = 1;
      else audioRef.current.audioEl.current.volume += 0.1;
    }
    if (isDown) {
      if (audioRef.current.audioEl.current.volume === 0.1)
        audioRef.current.audioEl.current.volume = 0.1;
      else audioRef.current.audioEl.current.volume -= 0.1;
    }
  };


  useEffect(() => {
    if (audioRef.current === null) return;
    if (musicChange) audioRef.current.audioEl.current.play();
    else  audioRef.current.audioEl.current.pause();
  }, [musicChange])
  
  return (
    <Flex
      w="100%"
      margin="auto 20px 100px 20px"
      maxW="220px"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
    >
      <Box
        onClick={() => changeVolume(true)}
        cursor="pointer"
        position="relative"
        w={{ base: "20px", md: "26px" }}
        h={{ base: "22px", md: "28px" }}
      >
        <Image
          w="100%"
          minW="100%"
          h="100%"
          minH="100%"
          position="absolute"
          src={Down}
        />
      </Box>
      <Box
        onClick={PlayPauseMusic}
        cursor="pointer"
        position="relative"
        w={{ base: "44px", md: "59px" }}
        h={{ base: "40px", md: "51px" }}
      >
        <Image
          w="100%"
          minW="100%"
          h="100%"
          minH="100%"
          position="absolute"
          src={!musicChange ? Play : Pause}
        />
      </Box>
      <Box
        onClick={() => changeVolume(false)}
        cursor="pointer"
        position="relative"
        w={{ base: "26px", md: "34px" }}
        h={{ base: "20px", md: "27px" }}
      >
        <Image
          w="100%"
          minW="100%"
          h="100%"
          minH="100%"
          position="absolute"
          src={Up}
        />
      </Box>
      <ReactAudioPlayer
        ref={audioRef}
        controls
        // type="audio/mpeg"
        style={{ display: "none" }}
        src={data?.stream.link}
      />
      {/* {audioRef.current !== null && (
      )} */}
    </Flex>
  );
};

export default Player;
