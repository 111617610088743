import { Flex, Text } from "@chakra-ui/react";
import React from "react";

const CopyRight = () => {
  const d = new Date();
  const year = d.getFullYear();

  return (
    <Flex
      pointerEvents="all"
      w="max-content"
      display={{ base: "none", md: "flex" }}
      zIndex="3"
      fontSize="12px"
      alignItems="center"
      color="#F2F2F2"
      flexDirection="column"
      position="absolute"
      bottom="50px"
      left="55px"
    >
      <Text>MIX FM , Copyright © 2021 - {year} </Text>
      <a href="https://orkabit.com/">
        Powered by <span style={{ fontWeight: "bold" }}>ORKA</span>BIT
      </a>
    </Flex>
  );
};

export default CopyRight;
