import { Box, Flex, Image } from "@chakra-ui/react";

import React, { useEffect, useState } from "react";

import backgroundHome from "../assets/backgroundImages/background-home.jpg";
import Navbar from "../components/Navbar";
import Player from "../components/Player";
import CopyRight from "../components/CopyRight";
import { ISlider } from "./Slider";
import Footer from "./Footer";
import OneOFive from "../assets/backgroundImages/oneOFive.svg";
import Syria from "../assets/backgroundImages/syria.svg";
import { IData } from "../App";
export interface IChildren extends IData {
  children: JSX.Element;
}
const Layout: React.FC<IChildren> = ({ children, data }) => {
  const [navbar, setNavabr] = useState<number>(0);

  const changeBg = () => {
    if (navbar === 0)
      return "linear-gradient( to right,rgb(17 18 140 / 30%), rgb(252 172 0 / 20%))";
    if (navbar === 1)
      return "linear-gradient( to right,rgb(17 18 140 / 30%), rgb(18 19 139 / 30%), rgb(252 172 0 / 26%))";
    if (navbar === 2)
      return "linear-gradient( to right,rgb(252 172 0 / 18%), rgb(18 19 139 / 30%), rgb(17 18 140 / 26%))";
  };
  const [windowSize, setWindowSize] = useState<ISlider["windowSize"]>({
    width: 0,
    height: 0,
  });
  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);
  return (
    <>
      <Flex
        overflow="hidden"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        position="relative"
        h="100vh"
        bg={`url(${backgroundHome})`}
        zIndex="1"
        minH="700px"
        w="100%"
      >
        <Image
          display={{ base: "none", md: "block" }}
          w="300px"
          opacity="0.6"
          position="absolute"
          src={Syria}
          right="0"
          h="100%"
        />
        <Box
          pointerEvents="none"
          transition="all 0.6s"
          backgroundSize="cover"
          w="100%"
          h="100%"
          bg={changeBg()}
          zIndex="2"
          position="absolute"
          top="0"
          left="0"
        />
        <Box
          pointerEvents="none"
          position="absolute"
          left="20px"
          top="10px"
          w={{
            base: "60%",
            md: "300px",
            lg: "300px",
            xl: "300px",
            "2xl": "500px",
          }}
          h="95vh"
          minH="700px"
        >
          <Image
            w="300px"
            opacity="0.6"
            pointerEvents="none"
            position="absolute"
            src={OneOFive}
            left="0"
            h="100%"
          />

          <CopyRight />
        </Box>

        <Flex
          marginRight={{
            base: "10px",
            md: "50px",
            lg: "60px",
            xl: "80px",
            "2xl": "150px",
          }}
          flexDir="column"
          h="100%"
          marginLeft={{
            base: "10px",
            md: "70px",
            lg: "150px",
            xl: "250px",
            "2xl": "500px",
          }}
          zIndex="3"
          w="100%"
          position="relative"
          flexDirection="row"
          gap={{
            base: "10px",
            md: "50px",
            lg: "80px",
            xl: "100px",
            "2xl": "150px",
          }}
          alignItems="center"
          justifyContent="start"
        >
          <Navbar navbar={navbar} setNavabr={setNavabr} />
          {children}
          <Player data={data} />
        </Flex>
        {windowSize.width < 768 && <Footer />}
        {/* <CopyRight /> */}
      </Flex>
    </>
  );
};
export default Layout;
